import "./index.css"
import img2 from "../../assets/images/arrow.png"
import img from "../../assets/images/partner.svg"
import img1 from "../../assets/images/heading.svg"



export default function Partner() {
    return (
        <div className="partner_container_wrapper">

            <div className="partner_container">

                <h1>From Our Partners</h1>
                <div className="partner_image">
                    <img src={img} alt="" />
                    <div className="arrow_img">
                        <img src={img2} alt="" />
                    </div>
                </div>
                <p>Joule Labs is the only fast-charging solution with immediate deployment for growing EV fleets and has the added bonus of cost savings plus future-proof technology</p>
                <h6>Founder, CEO</h6>
                <h5>Ain McKendrick</h5>
                <div className="partner_heading">
                    <img src={img1} alt="" />
                </div>
            </div>
            </div>

            )
}