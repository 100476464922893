import "./index.css"
import img1 from "../../assets/images/funds1.svg"
import img2 from "../../assets/images/funds2.svg"
import img3 from "../../assets/images/funds3.svg"
import img4 from "../../assets/images/fund5.png"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_left = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;
const fade_right = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;




export default function Funds() {
    return (
        <div className="funds_container_wrapper">

            <div className="funds_container">
                <div className="funds_content">
                    <Reveal triggerOnce={true} keyframes={fade_left}>
                        <h3>A finalist of PlugNPlay Detroit, we were awarded funds from the Michigan Mobility Fund in 2023 and 2024, and graduated as a Batch Four Startup in 2024! Look out for us back at the Detroit Smart Parking Lab and the Detroit Auto Show this coming September.</h3>
                        <div className="funds_icons">
                            <div className="funds_logo1"><img src={img2} alt="" /></div>
                            <div className="funds_logo2"><img src={img1} alt="" /></div>
                            <div className="funds_logo3"><img src={img3} alt="" /></div>
                        </div>
                    </Reveal>
                </div>
                <div className="funds_images">
                    <Reveal triggerOnce={true} keyframes={fade_right}>
                        <img src={img4} alt="" />
                    </Reveal>
                </div>
            </div>
        </div>

    )
}