import "./index.css"
import img1 from "../../assets/images/news1.png"
import img2 from "../../assets/images/news2.png"
import img3 from "../../assets/images/news3.png"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_top = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;

export default function News() {
    return (
        <div className="news_container_wrapper">
            <Reveal triggerOnce={true} keyframes={fade_top}>
            <div className="news_container" id="blog">
                <h1>Recent News</h1>
                <div className="news_wrapper">
                    <div className="news_image">
                        <div>
                            <img src={img3} alt="" />
                        </div>
                        <h2>Increase Accessibility</h2>
                        <p>Our Mobee systems make EV charging ADA accessible, and can also be used to support and ensure the safety of EV truck drivers.</p>
                    </div>
                    <div className="news_image">
                        <div>
                            <img src={img2} alt="" />
                        </div>                        <h2>Partner with Autonomous Fleets</h2>
                        <p>Our drive-through technology allows AVs to charge themselves, while the Mobee system itself can be mounted onto an AV so you can expand your service offerings.</p>
                    </div>
                    <div className="news_image">
                        <div>
                            <img src={img1} alt="" />
                        </div>                        <h2>Maximize Coverage </h2>
                        <p>Our battery-based fast charging system is in development and will allow you to turn any parking spot into an EV charging port.</p>
                    </div>
                </div>
            </div>
            </Reveal>
        </div>

    )
}