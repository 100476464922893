import "./index.css"
import img from "../../assets/images/charging1.webp"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_left = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;
const fade_right = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;


export default function Charging() {
    return (
        <div className="charging_container_wrapper">
            <div className="charging_container">
                <div className="charging_image">
                    <Reveal triggerOnce={true} keyframes={fade_left}>
                        <img src={img} alt="" />
                    </Reveal>
                </div>
                <div className="charging_content">
                    <Reveal triggerOnce={true} keyframes={fade_right}>
                        <h6>Your car can drive itself, can it charge itself?</h6>
                        <h1>Autonomous EV Charging Solutions</h1>
                        <p>At Joule Labs, we’re developing the future of mobility in EV charging. From mobile charging systems that integrate seamlessly with existing charging infrastructure, to automated solutions that increase accessibility, our robotics technology and Charging-As-A-Service platform works alongside humans and vehicles to make EV charging faster, easier, and future proof for charging vendors and end consumers.</p>
                    </Reveal>
                </div>
            </div>
        </div >

    )
}