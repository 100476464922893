import "./index.css"
import img1 from "../../assets/images/connect.png"
import img2 from "../../assets/images/headphone.svg"
import img3 from "../../assets/images/linkdin_icon.svg"
import img4 from "../../assets/images/youyube_icon.svg"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_top = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;

export default function Connect() {
    return (
        <div className="connect_container_wrapper">
<Reveal triggerOnce={true} keyframes={fade_top}>
            <div className="connect_container" id="contact">
                <div className="connect_image">
                    <img src={img1} alt="" />
                </div>
                <div className="connect_content">
                    <h1>Let’s Connect</h1>
                    <p>We’re currently working with EV fleet owners and managers to revolutionize their EV charging and maximize their vehicle uptime, but our automated EV charging platform is adaptable to a variety of situations. <br /><br />We’d love to chat with you whether that’s brainstorming ideas, talking partnerships, or getting a charge to your EV where its needed.</p>
                    <h3>Contact us</h3>
                    <div className="contact_content ">
                        <div className="contact_info">
                            <div><a href="mailto:info@joulelabs.com"><img src={img2} alt="" /></a></div>
                            <div><h2><a href="mailto:info@joulelabs.com">info@joulelabs.com</a></h2></div>
                        </div>
                        <div className="contact_icon">
                            <a href="https://www.linkedin.com/company/joule-labs-inc" target="/blank"><img src={img3} alt="" /></a>
                        </div>
                        <div className="contact_icon">
                            <a href="https://www.youtube.com/@joulelabs6043" target="/blank"><img src={img4} alt="" /></a>
                        </div>
                    </div>
                </div>

            </div>
            </Reveal>
        </div>
    )
}