import "./index.css"
import img1 from "../../assets/images/img3.svg"
import img2 from "../../assets/images/img4.svg"
import img3 from "../../assets/images/img5.svg"
import img4 from "../../assets/images/img6.svg"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_top = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;


export default function AutomationSection() {
    return (
        <div className="automation_container_wrapper">
            <Reveal triggerOnce={true} keyframes={fade_top}>

                <div className="automation_container">
                    <h1>What can Automation do for EV Charging?</h1>
                    <div className="automation_wrapper">
                        <div className="automation_image">
                            <div>
                                <img src={img4} alt="" />
                            </div>
                            <h2>Increase Accessibility for Drivers with Limited Mobility</h2>
                            <p>Our Mobee automated EV charging systems integrate with existing charginginfrastructure to support those with limited mobility, increasing EV access forhandicapped drivers. This system can also be used to support and ensure the safety of any EV driver, especially those working with heavy duty cables.</p>
                        </div>
                        <div className="automation_image">
                            <div>
                                <img src={img3} alt="" />
                            </div>
                            <h2>Enable Charging Optimization & Decrease Operational Costs for Driverless Vehicles</h2>
                            <p>Whether you want your connected and autonomous vehicles to drive through an automated EV charging dock, or to find the closest spot where our mobile charging system can meet them, we’ve got you covered. Our autonomous EV charging will help maximize your vehicle uptime </p>
                        </div>
                        <div className="automation_image">
                            <div>
                                <img src={img2} alt="" />
                            </div>
                            <h2>Decrease Vehicle Downtime for EV Fleet Operators</h2>
                            <p>Current EV charging infrastructure is costly to setup and you’re limited to not only charging locations, but also if those spots are available. We can integrate with existing parking or EV charging infrastructure to make sure your EVs are always charged and available to make it through your production queue, to transport your customers, and more.</p>
                        </div>
                        <div className="automation_image">
                            <div>
                                <img src={img1} alt="" />
                            </div>
                            <h2>Turn Any Parking Spot Into anEV Charging Port</h2>
                            <p>Our charging automation systems can easily add EV charging to automated parking structures, creating an additional opportunity to generate revenue without needing to hire staff or purchase a high quantity of costly EV chargers to guarantee coverage for your customers. Your structure can park and retrieve cars, why not charge them too?</p>
                        </div>
                    </div>
                </div>
            </Reveal>
        </div>

    )
}