import "./index.css"
import img1 from "../../assets/images/img1.svg"
import img2 from "../../assets/images/img2.svg"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_ = keyframes`
from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`;


export default function Handsfree() {
    return (
        <div className="handsfree_container_wrapper">
            <div className="handsfree_container">
                <div className="handsfree_content">
                    <h1>Handsfree EV Charging<br />with AI, Robotics & Machine Learning</h1>
                </div>
                <div className="handsfree_wrapper">
                    <div className="handsfree_image">
                        <Reveal triggerOnce={true} keyframes={fade_} duration={2500} >
                            <img src={img1} alt="" />
                        </Reveal>
                        <h1>Static Automated Charging</h1>
                    </div>
                    <div className="handsfree_image">
                        <Reveal triggerOnce={true} keyframes={fade_} duration={2500} >
                            <img src={img2} alt="" />
                        </Reveal>
                        <h1>Mobile Autonomous Charging</h1>
                    </div>
                </div>
            </div>
        </div>

    )
}