import "./index.css"
import img1 from "../../assets/images/team1.png"
import img2 from "../../assets/images/team2.png"
import img3 from "../../assets/images/team3.png"
import img4 from "../../assets/images/team4.png"
import img5 from "../../assets/images/team5.png"
import img6 from "../../assets/images/team6.png"
import img7 from "../../assets/images/team7.png"
import img8 from "../../assets/images/team8.png"
import img9 from "../../assets/images/team10.jpeg"
import img10 from "../../assets/images/team11.jpeg"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_ = keyframes`
from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`;


export default function Team() {
    return (
        <div className="team_container_wrapper">
            <Reveal triggerOnce={true} keyframes={fade_} duration={2500} >
                <div className="team_container">
                    <h1>Meet our Team</h1>
                    <div className="team_wrapper">
                       <div className="row row1">
                        <div><img src={img1} alt="" /></div>
                        <div><img src={img3} alt="" /></div>
                        <div><img src={img9} alt="" /></div>
                        <div><img src={img2} alt="" /></div>
                        <div><img src={img6} alt="" /></div>
                        </div>
                        <div className="row row2">
                        <div><img src={img8} alt="" /></div>   
                        <div><img src={img5} alt="" /></div>
                        <div><img src={img4} alt="" /></div>
                        <div><img src={img7} alt="" /></div>
                        <div><img src={img10} alt="" /></div>
                        </div>
                    </div>
                </div>
            </Reveal>
        </div>

    )
}