import "./index.css"
import img2 from "../../assets/images/headphone.svg"
import img3 from "../../assets/images/linkdin_icon.svg"
import img4 from "../../assets/images/youyube_icon.svg"


export default function Footer() {
    return (
        <div className="footer_container_wrapper">
            <div className="footer_container_wrapper_sub">

                <div className="footer_container">
                    <div className="footer_heading">
                        <h1>The future of EV mobility
                            is bringing the charge to you</h1>
                    </div>
                    <div className="footer_address">
                        <h2>929 Beryessa Rd San Jose, CA 95133</h2>
                        <h2>Michigan Central, <br /> 2050 15th St,  Detroit, <br /> MI 48216</h2>
                        <p><a href="tel:+16505690869">+1-650-569-0869</a></p>
                    </div>
                    <div className="contact_content footer_contact_content">
                        <div className="contact_info">
                            <div><a href="mailto:info@joulelabs.com"><img src={img2} alt="" /></a></div>
                            <div>
                                <h2><a href="mailto:info@joulelabs.com">info@joulelabs.com</a></h2>
                            </div>
                        </div>
                        <div className="footer_icons">
                            <div className="contact_icon">
                                <a href="https://www.linkedin.com/company/joule-labs-inc" target="/blank"><img src={img3} alt="" /></a>
                            </div>
                            <div className="contact_icon">
                                <a href="https://www.youtube.com/@joulelabs6043" target="/blank"><img src={img4} alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}