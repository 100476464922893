import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { useEffect } from "react";

import AutomationSection from "./components/automation_section";
import Charging from "./components/charging_section";
import Connect from "./components/connect_section";
import ImageSlider from "./components/cover";
import Footer from "./components/footer";
import Funds from "./components/funds_section";
import Handsfree from "./components/handsfree_section";
import Navbar from "./components/navbar";
import News from "./components/news_section";
import Partner from "./components/partner_section";
import Solutions from "./components/solution_section";
import Team from "./components/team_section";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return null;
}


function App() {
  return (
    <Router>
      {/* <ScrollToTop /> */}
      <Navbar />
      <ImageSlider />
      <Charging />
      <Handsfree />
      <News />
      <AutomationSection />
      <Funds />
      <Solutions />
      <Partner />
      <Team />
      <Connect />
      <Footer />
    </Router>
  );
}

export default App;
