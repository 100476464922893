import "./index.css";
import logo from "../../assets/images/logo.svg";
import linkdin from "../../assets/images/linkdin.svg";
import youtube from "../../assets/images/youtube.svg";
import { HashLink } from "react-router-hash-link";


import { HiMenu } from 'react-icons/hi'
import { useState, useEffect } from "react";
import { IoClose } from 'react-icons/io5'
import { FaYoutube, FaLinkedin, FaTwitch } from 'react-icons/fa';

export default function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [click, setClick] = useState(true);
    const handleClick = () => setClick(!click);
    return (
        <div className="navbar_wrapper" id="home">
            <div className="navbar_container">
                <div className="logo_conatainer">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="menu_toggle" onClick={() => setMenuOpen(!menuOpen)}>
                    <span className="menu_bar"></span>
                    <span className="menu_bar"></span>
                    <span className="menu_bar"></span>
                </div>

                <div className={`menu_items ${menuOpen ? 'open' : ''}`}>
                    <div className="menu_text">
                        <ul>
                            <HashLink smooth to={"/#home"}><li>Home</li></HashLink>
                            <HashLink smooth to={"/#solution"}><li>Solutions</li></HashLink>
                            <HashLink smooth to={"/#contact"}><li>Contact</li></HashLink>
                            <HashLink smooth to={"https://joulelabscom.wordpress.com"}><li>Blog</li></HashLink>
                        </ul>
                    </div>
                    <div className="menu_icon">
                        <ul>
                            <a href="https://www.linkedin.com/company/joule-labs-inc" target="/blank"><li><img src={linkdin} alt="LinkedIn" /></li></a>
                            <a href="https://www.youtube.com/@joulelabs6043" target="/blank"><li className="youtube_icon"><img src={youtube} alt="YouTube" /></li></a>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="m_navbar_main">
                <div className="m_navbar">
                    <div className="m_navbar_logo">
                        <HashLink smooth to={'/'}><img src={logo} alt="" /></HashLink>
                    </div>
                    <div className="icon_togle">

                        <span onClick={handleClick}><HiMenu /></span>
                    </div>
                </div>
                <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>

                    <div className="logo order-md-1">
                        <div className="fix-icon text-dark" onClick={handleClick}>
                            <IoClose />
                        </div>
                    </div>

                    <div className="navbar-nav">
                        <li className="nav-item">
                            <HashLink smooth to={"/#home"} className="nav-link" onClick={handleClick}>Home</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink smooth to={"/#solution"} className="nav-link" onClick={handleClick}>Solutions</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink smooth to={"/#contact"} className="nav-link" onClick={handleClick}>Contact</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink smooth to={"https://joulelabscom.wordpress.com/"} className="nav-link" onClick={handleClick}>Blog</HashLink>
                        </li>
                        <li className="icon_li">
                            <div className="icons_container_nav icons_container_nav_mob ">
                                <span className="icon1"><FaLinkedin /></span>
                                <span className="icon2"><FaYoutube /></span>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    )
}
