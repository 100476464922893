import "./index.css"
import img1 from "../../assets/images/team1.png"



export default function Solutions() {
    return (
        <div className="solution_container_wrapper">

            <div className="solution_container" id="solution">
                <h1>Mobee™ Charging Solutions</h1>
                <p>At Joule Labs, we offer cutting-edge solutions to meet the evolving needs of electric vehicle charging infrastructure. Our offerings include:</p>
                <div className="solution_wrapper">
                    <div className="solution_content">
                        <p>Automated Charging for Persons with Limited Mobility</p>
                    </div>
                    <div className="solution_content">
                        <p>Charging Automation for EV Fleets</p>
                    </div>
                    <div className="solution_content">
                        <p>Automated Charging for Large EV Depots</p>
                    </div>
                    <div className="solution_content">
                        <p>Charging Automation for Automated Parking Systems</p>
                    </div>
                    <div className="solution_content">
                        <p>On-Demand Autonomous Mobile Charging for High Occupancy Buildings & Parking Lots/Structures</p>
                    </div>
                    <div className="solution_content">
                        <p>A Network of Automated Charging Docks for Metropolitan Areas</p>
                    </div>
                </div>
            </div>

        </div>
    )
}