import React, { useState } from 'react';
import "./index.css";
import poster from "../../assets/images/poster.png"
import video from "../../assets/videos/optimized-video.mp4"

export default function Cover() {

  return (
    <div className="cover_container">
      <div className="cover_image">
        {/* <img src={images[currentImageIndex]} alt="" className={fadeClass} /> */}
        <div className="cover_image_bg"></div>

        <video poster={poster}  autoPlay loop muted playsInline className="fadeClass">
        <source src={video} type="video/mp4" />
         Your browser does not support the video tag.
      </video>
      </div>

      <div className="cover_content_wrapper">
        <div className="cover_content_wrapper_sub">
          <div className="cover_content">
            <h1>The Future<br /> of EV Mobility </h1>
            <p>is bringing the charge to you</p>
            <button>EXPLORE NOW</button>
          </div>
          {/* <div className='r_cover_btns'>
            <button onClick={handlePrevious}>
              <svg xmlns="http://www.w3.org /2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
                <path d="M12.1266 14.0009L17.5027 19.3768L15.9667 20.9128L9.05469 14.0009L15.9667 7.08887L17.5027 8.62486L12.1266 14.0009Z" fill="white" fillOpacity="0.7" />
              </svg>
            </button>
            <button onClick={handleNext}>
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
                <path d="M14.8724 14.0009L9.49635 19.3768L11.0323 20.9128L17.9443 14.0009L11.0323 7.08887L9.49635 8.62486L14.8724 14.0009Z" fill="white" fillOpacity="0.7" />
              </svg>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
